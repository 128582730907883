import {
  Address,
  BaseAddress,
  MultiAsset,
  Assets,
  ScriptHash,
  Costmdls,
  Language,
  CostModel,
  AssetName,
  TransactionUnspentOutput,
  TransactionUnspentOutputs,
  TransactionOutput,
  Value,
  TransactionBuilder,
  TransactionBuilderConfigBuilder,
  TransactionOutputBuilder,
  LinearFee,
  BigNum,
  BigInt,
  TransactionHash,
  TransactionInputs,
  TransactionInput,
  TransactionWitnessSet,
  Transaction,
  PlutusData,
  PlutusScripts,
  PlutusScript,
  PlutusList,
  Redeemers,
  Redeemer,
  RedeemerTag,
  Ed25519KeyHashes,
  ConstrPlutusData,
  ExUnits,
  Int,
  NetworkInfo,
  EnterpriseAddress,
  TransactionOutputs,
  hash_transaction,
  hash_script_data,
  hash_plutus_data,
  ScriptDataHash,
  Ed25519KeyHash,
  NativeScript,
  StakeCredential
} from "@emurgo/cardano-serialization-lib-asmjs";

// Protocol Parameters ---------------------------------------------------------
let protocolParams = {
  linearFee: {
    minFeeA: "44",
    minFeeB: "155381",
  },
  minUtxo: "34482",
  poolDeposit: "500000000",
  keyDeposit: "2000000",
  maxValSize: 5000,
  maxTxSize: 16384,
  priceMem: 0.0577,
  priceStep: 0.0000721,
  coinsPerUtxoWord: "34482",
}

// Blockchain Function ---------------------------------------------------------
export const getNetworkId = async (API) => {
    // Network IDs List
    //   0 : Test Net
    //   1 : Main Net
    try {
        const networkdID = await API.getNetworkId();
        return networkdID
    } catch (err) {
        console.log(err)
    }
};

export const initTransactionBuilder = async => {
  const txBuilder = TransactionBuilder.new(
    TransactionBuilderConfigBuilder.new()
    .fee_algo(LinearFee.new(BigNum.from_str(protocolParams.linearFee.minFeeA), BigNum.from_str(protocolParams.linearFee.minFeeB)))
    .pool_deposit(BigNum.from_str(protocolParams.poolDeposit))
    .key_deposit(BigNum.from_str(protocolParams.keyDeposit))
    .coins_per_utxo_word(BigNum.from_str(protocolParams.coinsPerUtxoWord))
    .max_value_size(protocolParams.maxValSize)
    .max_tx_size(protocolParams.maxTxSize)
    .prefer_pure_change(true)
    .build()
  );
  return txBuilder
};

export const getTxUnspentOutputs = async (UTXOs) => {
  let txOutputs = TransactionUnspentOutputs.new()
  for (const utxo of UTXOs) {
    txOutputs.add(utxo.TransactionUnspentOutput)
  }
  return txOutputs
};

export const isAssetHolder = async (userAssets, policyID, assetName, quantity=1) => {
  let isHolder = false;
  if (policyID) {
    if (policyID in userAssets) {
      //console.log('The user has this policy ID')
      if (assetName in userAssets[policyID]) {
        //console.log('The user has this asset')
        isHolder = true
      }
    }
  }
  return isHolder
};
