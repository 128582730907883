<template>
  <div class="flower-spinner">
    <div class="dots-container">
      <div class="bigger-dot">
        <div class="smaller-dot"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loader-flower-spinner",
}
</script>
