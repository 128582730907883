import { doc, getDoc, setDoc, addDoc, collection } from "firebase/firestore";
import {firestore} from "../firebase";

export const addCustomer = async (customerData) => {
    var date = new Date();
    const eventTitle = "MiamiFC"
    const collectionName = "inquries"
    try {
        await addDoc(collection(firestore, "customer"), {
            customerData
        });            
      } catch (e) {
        console.error("Error adding document: ", e);
    }
};

export const addProspectiveCustomer = async (email, eventTitle, eventOrganizer) => {
    var date = new Date();
    const collectionName = "prospectiveCustomers-CreditTx"
    try {
        await addDoc(collection(firestore, collectionName), {
            date: date,
            email: email,
            event: eventTitle,
            organizer: eventOrganizer,
        });            
      } catch (e) {
        console.error("Error adding document: ", e);
    }
};
