// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { initializeAnalytics, getAnalytics, logEvent } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCHJWrPpxUIW-6wrgUZKyTMZ16JB3dyrK4",
  authDomain: "mintmatrix-a722e.firebaseapp.com",
  databaseURL: "https://mintmatrix-a722e-default-rtdb.firebaseio.com",
  projectId: "mintmatrix-a722e",
  storageBucket: "mintmatrix-a722e.appspot.com",
  messagingSenderId: "992047785175",
  appId: "1:992047785175:web:6016b1737694a1a164bc92",
  measurementId: "G-T0EX5T26ZH"
};

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);
initializeAnalytics(firebase);
export const firestore = getFirestore(firebase);
export const functions = getFunctions(firebase);
export const analytics = getAnalytics(firebase);
export const db = firebase.firestore;
