<template>
  <footer class="footer px-4 mx-2">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{ year }}
          <a
            href="https://www.mintmatrix.io"
            class="font-weight-bold ml-1"
            target="_blank"
            >MintMatrix.io</a
          >
        </div>
      </div>
      <div class="col-lg-6">
        <ul
          class="nav nav-footer justify-content-center justify-content-lg-end"
        >
          <li class="nav-item">
            <a
              href="https://twitter.com/MintMatrix_io"
              class="nav-link"
              target="_blank"
              >
              <span class="btn-inner--icon">
                <i class="fab fa-twitter">
                </i> Follow Us
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://discord.com/invite/EnB6znbkZx"
              class="nav-link"
              target="_blank"
              >
              <span class="btn-inner--icon">
                <i class="fab fa-discord">
                </i> Discord
              </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              href="https://mintmatrix.io/mint-asset?e=1"
              class="nav-link"
              target="_blank"
              >
              <span class="btn-inner--icon">
                <i class="fas fa-ticket-alt">
                </i> Host an Event
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
