<template>
  <div>
    <base-header class="pb-6 px-2">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-6">
          <h5 class="h2 text-white d-inline-block mb-0">{{ $route.name }}</h5>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb />
          </nav>
        </div>
        <div class="col-lg-6 col-6 text-right">
          <span class="avatar avatar-sm">
            <img style="border-radius: 0.25rem !important;" alt="" src="img/icons/common/mint-matrix.png">
          </span>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6 px-2">
      <div class="row justify-content-center">
        <div class="col-lg-12 card-wrapper ">

          <!-- Modals -->
          <card>
            <template v-slot:header>
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0">Wallet Info</h3>
                </div>
                <div class="col-6 text-right" style="justify-content: end;">
                  <base-button class="" @click="modals.walletConnect = true" :class="walletState.status.connected === true ? 'btn-outline-success glow-success' : walletState.status.error === true ? 'btn-outline-danger  glow-error' : 'btn-outline-info'">
                    <span v-if="walletState.status.connected">
                      Connected
                    </span>
                    <span v-else>
                      Connect Wallet
                    </span>
                  </base-button>
                </div>
              </div>

            </template>
            <div v-if='walletState.status.connected'>
              <p class='mb-0'><span><b>Balance</b></span>: {{walletState.balance_ada}} &#8371;</p>
              <p class='mb-0'><span><b>Address</b></span>: <small>{{walletState.userAddress}}</small></p>
              <p v-if='devMode' class='mb-0'><span><b>UTXOs</b></span>: <small>{{walletState.UTXOs}}</small></p>
            </div>
            <div v-else>
              <p class='mb-0 text-center'>No wallet connected. Please connect with a Cardano wallet.</p>
            </div>
          </card>

          <!--Form modal-->
          <modal v-model:show="modals.walletConnect" size="sm" body-classes="p-0">
            <card type="secondary" header-classes="bg-transparent pb-4" body-classes="px-lg-5 py-lg-3" class="border-0 mb-0">
              <template v-slot:header style="background-color:'#00000'">
                <div class="text-muted text-center mt-2 mb-3">
                  <small>Sign in with wallet:</small>
                </div>
                <div class="row align-items-center">
                  <div class="col-6 pr-2">
                    <base-button icon @click="checkIfWalletFound('nami')" :disabled="walletState.status.connecting" class="btn-block" :class="walletState.status.connected && walletState.walletName == 'nami' ? 'btn-outline-success glow-success' : walletState.status.error  && walletState.walletName == 'nami' ? 'btn-outline-danger  glow-error' : 'btn-outline-info'">
                      <span class="btn-inner--icon"><img src="img/icons/common/nami-light.svg" /></span>
                      <span class="btn-inner--text">Nami</span>
                    </base-button>
                  </div>
                  <div class="col-6 pl-2">
                    <base-button icon @click="checkIfWalletFound('gerowallet')" :disabled="walletState.status.connecting" class="btn-block" :class="walletState.status.connected && walletState.walletName == 'gerowallet'  ? 'btn-outline-success glow-success' : walletState.status.error && walletState.walletName == 'gerowallet'  ? 'btn-outline-danger  glow-error' : 'btn-outline-info'">
                      <span class="btn-inner--icon"><img src="img/icons/common/gerowallet.png" /></span>
                      <span class="btn-inner--text">Gero</span>
                    </base-button>
                  </div>
                </div>
                <div class="row align-items-center">
                  <div class="col-6 pr-2">
                    <base-button icon @click="checkIfWalletFound('flint')" :disabled="walletState.status.connecting" class="btn-block" :class="walletState.status.connected && walletState.walletName == 'flint' ? 'btn-outline-success glow-success' : walletState.status.error && walletState.walletName == 'flint' ? 'btn-outline-danger  glow-error' : 'btn-outline-info'">
                      <span class="btn-inner--icon"><img src="img/icons/common/flint.png" /></span>
                      <span class="btn-inner--text">Flint</span>
                    </base-button>
                  </div>
                  <div class="col-6 pl-2">
                    <base-button icon @click="checkIfWalletFound('ccvault')" :disabled="walletState.status.connecting" class="btn-block" :class="walletState.status.connected && walletState.walletName == 'ccvault' ? 'btn-outline-success glow-success' : walletState.status.error && walletState.walletName == 'ccvault'  ? 'btn-outline-danger  glow-error' : 'btn-outline-info'">
                      <span class="btn-inner--icon"><img src="img/icons/common/eternl.png" /></span>
                      <span class="btn-inner--text">Eternl</span>
                    </base-button>
                  </div>
                </div>
              </template>

              <!-- Connection Feedback -->
              <div v-if="!isMobile()">
                <div v-if="walletState.status.error" class="row justify-content-center">
                  <p class="mb-0 text-danger">Issue with connecting to {{walletState.walletName[0].toUpperCase() + walletState.walletName.substring(1)}}</p>
                </div>
                <div v-if="walletState.status.connecting">
                  <div class="row justify-content-center mb-3">
                    <LoaderFlowerSpinner></LoaderFlowerSpinner>
                  </div>
                  <div class="row justify-content-center">
                    <span class="badge-dot mr-4 badge badge-">
                      <i class="bg-yellow"></i>
                      <span class="status">Connecting...</span>
                    </span>
                  </div>
                </div>
                <div v-if="walletState.status.connected" class="row justify-content-center">
                  <span class="badge-dot mr-4 badge badge-">
                    <i class="bg-success"></i>
                    <span class="status">Connected to {{walletState.walletName[0].toUpperCase() + walletState.walletName.substring(1)}}</span>
                  </span>
                </div>
                <div v-if="!walletState.status.connected && !walletState.status.connecting" class="row justify-content-center">
                  <span class="badge-dot mr-4 badge badge-">
                    <i class="bg-danger"></i>
                    <span class="status">Not Connected</span>
                  </span>
                </div>
              </div>
              <div v-else class="text-center">
                <p class="mb-0">View this page on a desktop to use this feature.</p>
              </div>
            </card>
          </modal>

          <div v-if='specialAuthToken == true' class="card">
            <div class="card-header p-3">
              <h5 class="mb-0">My Tickets</h5>
            </div>
            <div class="card-body p-3">
              <div class="row">
                <div class="col-3 text-center">
                </div>
                <div class="col-6 text-center">
                  <div class="border-dashed  py-3">
                    <div class="row">
                      <div class="col-6 text-center" style="display: flex; justify-content: center; align-items: center;">
                        <h4 class="text-primary mb-0">Event Ticket</h4>
                      </div>
                      <div class="col-6 text-center">
                        <qrcode-vue :value="QR_value" :size="QR_size" level="H" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-3 text-center">
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h5 class="mb-0">Event Details</h5>
              <div class="row">
                <div class="col-xl-5 col-lg-6">
                  <div class="row text-center mt-3">
                    <img style="width:200px" class="border-radius-lg mx-auto" src="https://www.miamifc.com/wp-content/uploads/sites/4/2021/11/miamifc-logo-website.png" alt="MiamiFC Tickets">
                  </div>
                  <hr>
                  <label class="mt-0">Description</label>
                  <p>Join us at Miami's crypto colesseum for this special event marking a world's first. Together we will make history, fully embracing the digital currency revolution with our host, Miami FC.</p>
                </div>
                <div class="col-lg-5 mx-auto">
                  <h2 class="mt-lg-0 mt-4">Miami FC <span class='text-muted'>vs</span> Detroit City FC <span class='text-muted'>tickets</span></h2>
                  <span v-if="inStock" class="badge badge-success">In Stock</span>
                  <span v-else class="badge badge-danger">Out of Stock</span>
                  <hr>
                  <h4 class='mb-2'>Wednesday, May 18 @ 6:40 - 9:40 PM</h4>
                  <h4 class='mb-0'>Riccardo Silva Stadium<br><span class='text-muted'>11310 SW 17th St, Miami, FL</span></h4>
                  <hr>
                  <div class="row mt-0">
                    <div class="col-lg-6">
                      <div class="row mt-1">
                        <div class="col-7">
                          <h4 class="mb-0">Base Price:</h4>
                        </div>
                        <div class="col-5 text-right">
                          <span class='text-muted'>$ {{unitCost_usd}}</span>
                        </div>
                      </div>
                      <div class="row mt-0">
                        <div class="col-7">
                          <h4 class="mb-0 mt-0">Crypto Price:</h4>
                        </div>
                        <div class="col-5 text-right">
                          <span class='text-muted'>{{unitCost_ada}} &#8371;</span>
                        </div>
                      </div>
                      <div class="row mt-0">
                        <div class="col-7">
                          <h4 class="mb-0 mt-0">Price Lock:</h4>
                        </div>
                        <div class="col-5 text-right">
                          <span class='text-muted'>{{timerMin}}:{{timerSec}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <form>
                        <base-input name="select" label="Quantity">
                          <select class="form-control" id="quantity_select" @change="updateTotal($event)">
                            <option value=1>1</option>
                            <option value=2>2</option>
                            <option value=3>3</option>
                            <option value=4>4</option>
                            <option value=5>5</option>
                            <option value=6>6</option>
                            <option value=7>7</option>
                            <option value=8>8</option>
                            <option value=9>9</option>
                            <option value=10>10</option>
                          </select>
                        </base-input>
                      </form>
                    </div>
                  </div>

                  <div class="row mt-0">
                    <div class="col-7">
                      <h4 class="mb-0 mt-0">SubTotal:</h4>
                    </div>
                    <div class="col-5 text-right">
                      <span class='text-default'>{{totalCost_ada}} &#8371;</span>
                      <br>
                      <span class='text-muted'>$ {{totalCost_usd}}</span>
                    </div>
                  </div>

                  <div class="row mt-4">
                    <div class="col-6">
                      <button @click="modals.emailWaitlist = true" class="btn btn-checkout-page btn-block mb-0 mt-lg-auto" type="button" name="button">
                        Credit
                      </button>
                    </div>
                    <div class="col-6">
                      <button @click="modals.confirmTX = true" class="btn btn-checkout-page btn-block mb-0 mt-lg-auto" type="button" name="button">
                        Crypto
                      </button>
                    </div>

                    <!--Confirm TX Modal-->
                    <modal v-model:show="modals.confirmTX" size="sm" body-classes="p-0">
                      <card type="secondary" header-classes="bg-transparent pb-4" body-classes="px-lg-5 py-lg-3" class="border-0 mb-0">
                        <template v-slot:header>

                          <div class="text-muted text-center mt-2 mb-0">
                            <div v-if="!formConfirmTxIsLoading && !formConfirmTxIsSubmitted">
                              <h3>Confirm Transaction</h3>
                              <small>Enter an email to recieve an email confirmation.</small>
                              <form>
                                <div class="form-group mb-3 mt-3">
                                  <div class="input-group input-group-alternative">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">
                                        <i class="ni ni-email-83"></i>
                                      </span>
                                    </div>
                                    <input name="email" v-model="formConfirmTx.email" class="form-control" placeholder="Email">
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div v-else-if="formConfirmTxIsLoading">
                              <h3 class='mb-0 text-info'>Confirming Transaction</h3>
                            </div>
                            <div v-else-if="formConfirmTxIsSubmitted">
                              <h3 class='mb-0 text-success'>Transaction Confirmed</h3>
                            </div>
                          </div>
                        </template>
                        <h3>Transaction Details</h3>
                        <div class="row mt-0">
                          <div class="col-6">
                            <h4 class="mb-0 mt-0">Tickets:</h4>
                          </div>
                          <div class="col-6 text-right">
                            <span class='text-muted'>{{quantity}} x {{unitCost_ada}} &#8371;</span>
                          </div>
                        </div>
                        <div class="row mt-0">
                          <div class="col-8">
                            <h4 class="mb-0 mt-0">Transaction Fee
                              <el-tooltip placement="top" content="This includes a small network fee of ~0.3 ADA. The remainder is our processing fee.">
                                <i class="fas fa-question-circle"></i>
                              </el-tooltip>:
                            </h4>
                          </div>
                          <div class="col-4 text-right">
                            <span class='text-muted'>{{(txFee)}} &#8371;</span>
                          </div>
                        </div>
                        <div class="row mt-0">
                          <div class="col-8">
                            <h4 class="text-muted mb-0 mt-0">Refundable Deposit
                              <el-tooltip placement="top" content="This amount will be returned to you together with the tokenized tickets.">
                                <i class="fas fa-question-circle"></i>
                              </el-tooltip>:
                            </h4>
                          </div>
                          <div class="col-4 text-right">
                            <span class='text-muted'>{{(txFee_refundable)}} &#8371;</span>
                          </div>
                        </div>
                        <hr class='mb-1 mt-1'>
                        <div class="row mt-0">
                          <div class="col-6">
                            <h4 class="mb-0 mt-0">Total:</h4>
                          </div>
                          <div class="col-6 text-right">
                            <h4>{{totalCost_ada_wFee}} &#8371;</h4>
                          </div>
                        </div>

                        <div class="card-footer pb-0" style="background-color:#f7fafc">
                          <div class="text-center">
                            <div v-if="formConfirmTxIsLoading">
                              <LoaderLoopingRhombus></LoaderLoopingRhombus>
                            </div>
                            <div v-else-if="formConfirmTxIsSubmitted">
                              <div class="mt-3 mb-4 text-center">
                                <h3 class="mb-0">Thank you for your purchase!</h3>
                                <br>
                                <small>Your transaction has been recieved. Expect delivery of your tickets soon. We're excited to see you at the event!</small>
                              </div>
                            </div>
                            <div v-else>
                              <base-button @click="executeTX" class="my-4 btn-miami-theme btn-miami-theme-pspin" style="width:200px">
                                Confirm Transaction
                              </base-button>
                            </div>
                            <div v-if=" !walletState.status.connected">
                              <h4 class="mb-3" :class="formConfirmTxHasErrored ? 'text-danger' : 'text-info' ">Connect to a Wallet</h4>
                            </div>
                            <div v-else-if="walletState.status.connected & walletState.networkId == 0">
                              <h4 class="mb-3" :class="formConfirmTxHasErrored ? 'text-danger' : 'text-info' ">You are on the TestNet</h4>
                            </div>
                            <div v-if="devMode">
                              <h4 class="mb-3 text-yellow">Developer Mode is Enabled<br>(Tx will execute on TestNet)</h4>
                            </div>
                          </div>
                        </div>
                      </card>
                    </modal>

                    <!-- Email Waitlist Modal -->
                    <modal v-model:show="modals.emailWaitlist" size="sm" body-classes="p-0">
                      <card type="secondary" header-classes="bg-transparent pb-4" body-classes="px-lg-5 py-lg-3" class="border-0 mb-0">
                        <template v-slot:header style="background-color:'#00000'">
                          <div class="text-muted text-center mt-2 mb-0">
                            <h3>Join the Waitlist</h3>
                            <small>We are currently only supporting cardano payments at this moment. Let us know if you're still interested and get notified as soon as tickets for this event become available through credit/debit transactions.</small>
                          </div>
                        </template>
                        <div v-if="formNotifyIsLoading">
                          <LoaderLoopingRhombus></LoaderLoopingRhombus>
                        </div>
                        <div v-else-if="formNotifyIsSubmitted">
                          <div class="mt-3 mb-4 text-center">
                            <h3 class="mb-0">Thank you for your interest!</h3>
                            <br>
                            <small>Your email has been added to the waitlist. We'll let you know as soon as this event begins to sell tickets!</small>
                          </div>
                        </div>
                        <div v-else>
                          <form role="form">
                            <div class="form-group mb-3 mt-3">
                              <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">
                                    <i class="ni ni-email-83"></i>
                                  </span>
                                </div>
                                <input name="email" v-model="formNotify.email" class="form-control" placeholder="Email">
                              </div>
                            </div>
                          </form>
                          <div class="text-center">
                            <base-button @click="addEmailToWaitlist" class="btn-miami-theme btn-miami-theme-pspin my-4" style="width:200px">
                              <i class="ni ni-bell-55 opacity-10" aria-hidden="true"></i>
                              Keep Me Posted
                            </base-button>
                          </div>
                        </div>
                      </card>
                    </modal>
                  </div>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-12">
                  <label class="mt-0">Additional Information</label>
                  <ul>
                    <li>Wear the colors and swag of your favorite crypto.</li>
                    <li>The FC will mint NFTs in the crypto that exhibits the largest presence and spirit.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div v-if='isAvailable_ReferralProgram' class="card">
            <div class="card-header p-3">
              <h5 class="mb-2">Referral Program</h5>
              <p class="mb-0">Track and find all the details about our referral program, your stats and revenues.</p>
            </div>
            <div class="card-body p-3">
              <div class="row">
                <div class="col-lg-3 col-6 text-center">
                  <div class="border-dashed  py-3">
                    <h4 class="text-primary mb-0">Earnings</h4>
                    <h3 class="font-weight-bolder"><span class="small">$ </span><span id="state1" countto="23980">23,980</span></h3>
                  </div>
                </div>
                <div class="col-lg-3 col-6 text-center">
                  <div class="border-dashed  py-3">
                    <h4 class="text-primary mb-0">Customers</h4>
                    <h3 class="font-weight-bolder"><span class="small">$ </span><span id="state2" countto="2400">2,400</span></h3>
                  </div>
                </div>
                <div class="col-lg-3 col-6 text-center mt-4 mt-lg-0">
                  <div class="border-dashed  py-3">
                    <h4 class="text-primary mb-0">Avg. Value</h4>
                    <h3 class="font-weight-bolder"><span class="small">$ </span><span id="state3" countto="48">48</span></h3>
                  </div>
                </div>
                <div class="col-lg-3 col-6 text-center mt-4 mt-lg-0">
                  <div class="border-dashed  py-3">
                    <h4 class="text-primary mb-0">Refund Rate</h4>
                    <h3 class="font-weight-bolder"><span id="state4" countto="4">4</span><span class="small"> %</span></h3>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-lg-5 col-12">
                  <h5 class="mb-0">Referral Code</h5>
                  <p class="text-sm">Copy the code bellow to your registered provider.</p>
                  <div class="border-dashed  p-3">
                    <p class="text-xs mb-2">Generated 23 days ago by <span class="font-weight-bolder">softuidash23</span></p>
                    <p class="text-xs mb-3"><span class="font-weight-bolder">(Used one time)</span></p>
                    <div class="d-flex align-items-center">
                      <div class="form-group w-70">
                        <div class="input-group bg-gray-200">
                          <input class="form-control form-control-sm" value="argon-dashboard-vmsk392" type="text" disabled="" onfocus="focused(this)" onfocusout="defocused(this)">
                          <span class="input-group-text bg-transparent" data-bs-toggle="tooltip" data-bs-placement="top" title="" data-bs-original-title="Referral code expires in 24 hours" aria-label="Referral code expires in 24 hours"><i class="ni ni-key-25"></i></span>
                        </div>
                      </div>
                      <a href="javascript:;" class="btn btn-sm btn-outline-secondary ms-2 px-3">Copy</a>
                    </div>
                    <p class="text-xs mb-1">You cannot generate codes.</p>
                    <p class="text-xs mb-0"><a href="javascript:;">Contact us</a> to generate more referrals link.</p>
                  </div>
                </div>
                <div class="col-lg-7 col-12 mt-4 mt-lg-0">
                  <h5 class="mb-0">How to use</h5>
                  <p class="text-sm">Integrate your referral code in 3 easy steps.</p>
                  <div class="row">
                    <div class="col-md-4 col-12">
                      <div class="card card-plain text-center">
                        <div class="card-body">
                          <div class="icon icon-shape bg-gradient-dark shadow text-center border-radius-md mb-2">
                            <i class="ni ni-money-coins opacity-10" aria-hidden="true"></i>
                          </div>
                          <p class="text-sm font-weight-bold mb-2">1. Create &amp; validate your referral link and get </p>
                          <h5 class="font-weight-bolder"><span class="small">$</span>100</h5>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="card card-plain text-center">
                        <div class="card-body">
                          <div class="icon icon-shape bg-gradient-dark shadow text-center border-radius-md mb-2">
                            <i class="ni ni-send opacity-10" aria-hidden="true"></i>
                          </div>
                          <p class="text-sm font-weight-bold mb-2">2. For every order you make you get </p>
                          <h5 class="font-weight-bolder">10<span class="small">%</span></h5>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12">
                      <div class="card card-plain text-center">
                        <div class="card-body">
                          <div class="icon icon-shape bg-gradient-dark shadow text-center border-radius-md mb-2">
                            <i class="ni ni-spaceship opacity-10" aria-hidden="true"></i>
                          </div>
                          <p class="text-sm font-weight-bold mb-2">3. Get other friends to generate link and get </p>
                          <h5 class="font-weight-bolder"><span class="small">$</span>500</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="horizontal dark">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Import Utiltiy Functions
import axios from "axios";
import {emailConfirmTX} from "@/util/mail.js"
import {validateEmail} from '@/util/formTools.js'
import {
  Address,
  BaseAddress,
  MultiAsset,
  Assets,
  ScriptHash,
  Costmdls,
  Language,
  CostModel,
  AssetName,
  TransactionUnspentOutput,
  TransactionUnspentOutputs,
  TransactionOutput,
  Value,
  TransactionBuilder,
  TransactionBuilderConfigBuilder,
  TransactionOutputBuilder,
  LinearFee,
  BigNum,
  BigInt,
  TransactionHash,
  TransactionInputs,
  TransactionInput,
  TransactionWitnessSet,
  Transaction,
  PlutusData,
  PlutusScripts,
  PlutusScript,
  PlutusList,
  Redeemers,
  Redeemer,
  RedeemerTag,
  Ed25519KeyHashes,
  ConstrPlutusData,
  ExUnits,
  Int,
  NetworkInfo,
  EnterpriseAddress,
  TransactionOutputs,
  hash_transaction,
  hash_script_data,
  hash_plutus_data,
  ScriptDataHash,
  Ed25519KeyHash,
  NativeScript,
  StakeCredential
} from "@emurgo/cardano-serialization-lib-asmjs";
import {
  blake2b
} from "blakejs";
var Buffer = require('buffer/').Buffer
let blake = require('blakejs')

// Import HTML Components
import RouteBreadcrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";
import Modal from "@/components/Modal";
import LoaderLoopingRhombus from '@/components/Loaders/LoopingRhombus.vue'
import LoaderFlowerSpinner from '@/components/Loaders/FlowerSpinner.vue'
import QrcodeVue from 'qrcode.vue'

// Import Blockchain Functions
import {getNetworkId, initTransactionBuilder, getTxUnspentOutputs, isAssetHolder} from '@/blockchain/cardano/wallet.js'

// Import Database Functions
import {
  linkUserToDB,
  addWalletEvent,
  walletExists,
} from "@/database/wallet.js";
import {
  addCustomer,
  addProspectiveCustomer,
} from "@/database/customer.js";

export default {
  components: {
    QrcodeVue,
    LoaderLoopingRhombus,
    LoaderFlowerSpinner,
    Modal,
    BaseHeader,
    RouteBreadcrumb,
  },
  data() {
    return {

// Page ------------------------------------------------------------------------
      // Development Parameters
      devMode: 1,
      verbose: 0,
      isAvailable_EmailConfirmation: 1, // Disables email confirmation (... until we can afford it)
      isAvailable_ReferralProgram: 0, // Disables referral card info.
      isAvailable_CreditCheckout: 0, // Disables credit checkout. Opens modal to provide email

      // Component: Modals
      modals: {
        classic: false,
        notice: false,
        form: false,
      },

      // Component: Form - Email List
      formNotifyIsLoading: false,
      formNotifyIsSubmitted: false,
      formNotify: {
        email: "",
      },

      // Component: Form - Confirm TX
      formConfirmTxHasErrored: false,
      formConfirmTxIsLoading: false,
      formConfirmTxIsSubmitted: false,
      formConfirmTxErrMsg: false,
      formConfirmTx: {
        email: "",
      },

      // Timer
      timerMin: 9,
      timerSec: 0,
      priceLockExpiration: 600,

      // Event Info
      ticketsInStock: null,
      eventOrganizer: 'Miami FC',
      eventTitle: 'Miami FC vs Detroit City FC',
      eventLocation: '<br>Riccardo Silva Stadium<br>11310 SW 17th St, Miami, FL',
      eventDate: 'Wednesday, May 18 @ 6:40 - 9:40 PM',

      // Tick Info
      QR_value: 'https://google.com',
      QR_size: 120,

      // Checkout System
      quantity: 1,
      inStock: 1,
      txFee: 1.00,
      txFee_refundable: 2,
      ada_usd: null, // Get from API
      unitCost_usd: 15,
      unitCost_ada: null,
      baseUnit: 'usd',
      totalCost_usd: 15,
      totalCost_ada: null,
      totalCost_ada_wFee: null,

// Blockchain Integration ------------------------------------------------------
      // Special Token Auth
      // policyIDCheck: 'e9a781dd7ff9bd178b106108ed084085bef87f56e1fa4c4717681c5b',
      // assetCheck: 'CardanoBeach',
      // To invoke --> const isMember = await isAssetHolder(this.walletState.assets , this.policyIDCheck, this.assetCheck, this.quantityCheck)
      policyIDCheck: null, 
      assetCheck: null,
      quantityCheck: null,
      specialAuthToken: null,

      // Vendor Info
      vendorInfo: {
        vendAddressBech32: "addr1v8kufnf8ycg6j3ter766w39q8xthdlafpacv76mv644z3yq298gye",
        vendAddressBech32_test: "addr_test1qqr585tvlc7ylnqvz8pyqwauzrdu0mxag3m7q56grgmgu7sxu2hyfhlkwuxupa9d5085eunq2qywy7hvmvej456flknswgndm3",
        plutusScriptAddressBech32: "",
        plutusScriptCborHex: "",
        productInfo: {
          assetNameHex: "",
          assetPolicyIdHex: "",
        },
      },

      // Wallet State
      walletState: {
        walletName: null,
        networkId: null,
        userAddress: null,
        balance_ada: null,
        UTXOs: null,
        assets: null,
        status: {
          connecting: false,
          connected: false,
          error: false,
        },
      },
      API: undefined,
      walletFound: {
        nami: null,
        flint: null,
        gerowallet: null,
        ccvault: null,
      },
    };
  },

  beforeMount() {
    this.getCryptoPrice()
  },

  watch: {

    priceLockExpiration: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.priceLockExpiration--;
            this.timerMin = Math.floor(this.priceLockExpiration / 60)
            this.timerSec = String(this.priceLockExpiration % 60).padStart(2, '0')
          }, 1000);
        } else {
          this.priceLockExpiration = 600
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    },
    eventTicketSaleStart: {

    },
  },
  methods: {
    // Utility Functions -------------------------------------------------------
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    getCryptoPrice() {
      axios
        .get('https://api.coingecko.com/api/v3/simple/price?ids=cardano&vs_currencies=usd')
        .then(response => {
          this.ada_usd = parseFloat(response.data.cardano.usd);
          this.unitCost_ada = (this.unitCost_usd / this.ada_usd).toFixed(2);
          this.totalCost_ada = (this.totalCost_usd / this.ada_usd).toFixed(2);
          this.totalCost_ada_wFee = (Number(this.totalCost_ada) + Number(this.txFee) + Number(this.txFee_refundable)).toFixed(2);
        })
    },
    // DB Functions ------------------------------------------------------------
    txInfo(txHash){
      const info = {
        // Customer
        email: this.formConfirmTx.email,
        addr: this.walletState.userAddress,
        userBalance: this.walletState.balance_ada,
        walletName: this.walletState.walletName,
        // TX
        txHash: txHash,
        quantity: this.quantity,
        totalCost_ada: this.totalCost_ada_wFee,
        ada_usd: this.ada_usd,
        checkoutTime: new Date(),
        // Event
        eventOrganizer: this.eventOrganizer,
        eventTitle: this.eventTitle,
        eventDate: this.eventDate,
        eventLocation: this.eventLocation
      }
      if (this.verbose) {
        console.log(info)
      }
      return info
    },
    async addCustomerToDB(txHash) {
      const customerInfo = {
        addr: this.walletState.userAddress,
        quantity: this.quantity,
        totalCost_ada: this.totalCost_ada_wFee,
        ada_usd: this.ada_usd,
        userBalance: this.walletState.balance_ada,
        walletName: this.walletState.walletName,
        email: this.formConfirmTx.email,
        eventOrganizer: this.eventOrganizer,
        eventTitle: this.eventTitle,
        checkoutTime: new Date(),
        txHash: txHash
      }
      if (this.verbose) {
        console.log(customerInfo)
      }
      await addCustomer(customerInfo);
    },
    async addUserToDB(txHash) {
      const userInfo = {
        addr: this.walletState.userAddress,
        userBalance: this.walletState.balance_ada,
        walletName: this.walletState.walletName,
        loginTime: new Date()
      }
      if (this.verbose) {
        console.log(userInfo)
      }
      const data = await linkUserToDB(userInfo)
    },
    // Form Submits ------------------------------------------------------------
    async addEmailToWaitlist() {
      if (validateEmail(this.formNotify.email)) {
        this.formNotifyIsLoading = true
        //await addProspectiveCustomer(this.formNotify.email, this.eventTitle, this.eventOrganizer)
        this.formNotifyIsLoading = false;
        this.formNotifyIsSubmitted = true;
      }
    },
    updateTotal() {
      this.quantity = event.target.value;
      this.totalCost_usd = (this.unitCost_usd * event.target.value).toFixed(2)
      this.totalCost_ada = (this.unitCost_ada * event.target.value).toFixed(2)
      this.totalCost_ada_wFee = (Number(this.totalCost_ada) + Number(this.txFee) + Number(this.txFee_refundable)).toFixed(2)
    },

    // Blockchain Integration --------------------------------------------------
    async enableWallet(walletName) {
      try { // Try to enable the wallet
        this.API = await window.cardano[walletName].enable();
        if (this.verbose) {
          console.log('Wallet API Response: ')
        }
        if (this.verbose) {
          console.log(this.API)
        }
        // If wallet enabled, try to obtain information about the connected wallet
        if (this.API) {
          this.walletState.networkId = await getNetworkId(this.API);
          if (this.verbose) {
            console.log('Using Network ID: ' + this.walletState.networkId)
          }
          try {
            // Get Wallet Balance
            if (this.verbose) {
              console.log('Attempting to retrieve balance...')
            }
            const balanceCBORHex = await this.API.getBalance();
            const balance_lovelace = Value.from_bytes(Buffer.from(balanceCBORHex, "hex")).coin().to_str();
            const balance_ada = balance_lovelace / 1000000
            this.walletState.balance_ada = balance_ada
            if (this.verbose) {
              console.log('Wallet Balance: ' + String(balance_ada))
            }

            // Get Wallet Address
            if (this.verbose) {
              console.log('Attempting to retrieve address...')
            }
            const raw = await this.API.getChangeAddress();
            const changeAddress = Address.from_bytes(Buffer.from(raw, "hex")).to_bech32()
            this.walletState.userAddress = changeAddress
            if (this.verbose) {
              console.log('Wallet Change Address: ' + String(changeAddress))
            }

            // Get breakdown of UTXOs
            if (this.verbose) {
              console.log('Attempting to retrieve UTXOs...')
            }
            let Utxos = [];
            const rawUtxos = await this.API.getUtxos();
            for (const rawUtxo of rawUtxos) {
              const utxo = TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
              const input = utxo.input();
              const txid = Buffer.from(input.transaction_id().to_bytes(), "utf8").toString("hex");
              const txindx = input.index();
              const output = utxo.output();
              const amount = output.amount().coin().to_str(); // ADA amount in lovelace
              const multiasset = output.amount().multiasset();
              const userAssets = {};
              let multiAssetStr = "";
              if (multiasset) {
                const keys = multiasset.keys() // policy Ids of thee multiasset
                const N = keys.len();
                if (this.verbose) {
                  console.log(`${N} Multiassets in the UTXO`)
                }
                for (let i = 0; i < N; i++){
                    const policyId = keys.get(i);
                    const policyIdHex = Buffer.from(policyId.to_bytes(), "utf8").toString("hex");
                    const assets = multiasset.get(policyId)
                    const assetNames = assets.keys();
                    const K = assetNames.len()
                    userAssets[policyIdHex] = {}
                    if (this.verbose) {
                      console.log(`policyId: ${policyIdHex}`)
                      console.log(`${K} Assets in the Multiasset`)
                    }
                    for (let j = 0; j < K; j++) {
                        const assetName = assetNames.get(j);
                        const assetNameString = Buffer.from(assetName.name(),"utf8").toString();
                        const assetNameHex = Buffer.from(assetName.name(),"utf8").toString("hex")
                        const multiassetAmt = multiasset.get_asset(policyId, assetName)
                        multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`
                        userAssets[policyIdHex] = {...userAssets[policyIdHex], [assetNameString] : +multiassetAmt.to_str() }
                        if (this.verbose) {
                          console.log(`Asset Name: ${assetNameHex}`)
                          console.log('multiassetAmt', multiassetAmt)
                          console.log('assetNameString', assetNameString)
                          console.log('multiassetAmt.to_str()', multiassetAmt.to_str())
                        }
                      }
                }
                if (this.verbose) {
                  console.log(userAssets)
                }
                this.walletState.assets = userAssets;
              }
              const obj = {
                txid: txid,
                txindx: txindx,
                amount: amount,
                str: `${txid} #${txindx} = ${amount}`,
                multiAssetStr: multiAssetStr,
                TransactionUnspentOutput: utxo
              }
              Utxos.push(obj);
            }
            this.walletState.UTXOs = Utxos;
            if (this.verbose) {
              console.log('UTXOs: ' + JSON.stringify(Utxos))
            }

          } catch (err) {
            console.log('ERROR: Unable to obtain information from wallet.')
            if (this.verbose) {
              console.log(err)
            }
            this.walletState.status.connecting = false;
            this.walletState.status.error = true;
          }
        } else {
          console.log('ERROR: Unable to form connection with wallet.')
          if (this.verbose) {
            console.log(err)
          }
          this.walletState.status.connecting = false;
          this.walletState.status.error = true;
        }
      } catch (err) {
        console.log('ERROR: Unable to form connection with wallet.')
        if (this.verbose) {
          console.log(err)
        }
        this.walletState.status.connecting = false;
        this.walletState.status.error = true;
      }
      if (!this.walletState.status.error) {
        // Set wallet state displays to true if no errors were thrown
        await this.addUserToDB()
        this.walletState.status.connecting = false;
        this.walletState.status.connected = true;
      }
    },

    async checkIfWalletFound(walletName) {

      // Nullify walletFound
      this.walletFound.ccvault = null;
      this.walletFound.flint = null;
      this.walletFound.gerowallet = null;
      this.walletFound.nami = null;

      // Nullify wallet states and elevate walletName local state to 'global state'
      this.walletState = {
        walletName: walletName,
        userAddress: null,
        balance_ada: null,
        UTXOs: null,
        status: {
          connecting: true,
          connected: false,
          error: false,
        }
      }
      this.API = null

      // Nullify Error
      this.formConfirmTxHasErrored = false;

      // Check if requested wallet is found
      if (this.verbose) {
        console.log('Checking to see if ' + walletName + ' wallet is found: ')
      }
      this.walletFound[walletName] = !!window?.cardano[walletName];

      // If true, lets connect to the wallet
      if (this.walletFound[walletName]) {
        if (this.verbose) {
          console.log('Wallet was found. Beginning wallet connection attempt: ')
        }
        await this.enableWallet(walletName)
        this.specialAuthToken = await isAssetHolder(this.walletState.assets , this.policyIDCheck, this.assetCheck, this.quantityCheck)
      } else {
        this.walletState.status.error = true;
        this.walletState.status.connecting = false;
        this.walletState.status.connected = false;
      }
    },

    /* ------- TRANSACTION BUILDING ------- */
    async executeTX() {

      if (this.verbose) {
        console.log('Attempting to excute transaction using wallet: ' + this.walletState.walletName)
      }

      // If wallet is connected, attempt to complete the transaction
      if (this.walletState.status.connected) {
        if (this.walletState.networkId == 1 || this.devMode == 1) {

          this.formConfirmTxIsLoading = true;
          try {
            // Obtain the value to send
            const lovelaceToSend = 0;//(this.totalCost_ada_wFee * 1000000).toFixed(0)
            if (this.verbose) {
              console.log('Lovelaces to send: ' + lovelaceToSend)
            }

            // Get the vendor and user addresses
            let recipientAddr;
            if (this.devMode) {
              recipientAddr = this.vendorInfo.vendAddressBech32_test;
              if (this.verbose) {
                console.log('INFO: Sending to TEST vendor address: ' + recipientAddr)
              }
            } else {
              recipientAddr = this.vendorInfo.vendAddressBech32;
              if (this.verbose) {
                console.log('INFO: Sending to LIVE vendor address: ' + recipientAddr)
              }
            }
            const shelleyOutputAddress = Address.from_bech32(recipientAddr);
            const shelleyChangeAddress = Address.from_bech32(this.walletState.userAddress);
            if (this.verbose) {
              console.log('INFO: User address: ' + this.walletState.userAddress)
            }

            // Construct the TX
            if (this.verbose) {
              console.log('INFO: Constructing the transaction')
            }
            const txBuilder = await initTransactionBuilder();
            txBuilder.add_output(
              TransactionOutput.new(
                shelleyOutputAddress,
                Value.new(BigNum.from_str(lovelaceToSend.toString()))
              ),
            );

            // Find the available UTXOs in the wallet and use them as Inputs
            // Then calculate the min fee required and send any change to an address
            const txUnspentOutputs = await getTxUnspentOutputs(this.walletState.UTXOs);
            txBuilder.add_inputs_from(txUnspentOutputs, 1)
            txBuilder.add_change_if_needed(shelleyChangeAddress)

            // Once the transaction is ready, we build it to get the tx body without witnesses
            const txBody = txBuilder.build();

            // Tx witness
            const transactionWitnessSet = TransactionWitnessSet.new();
            const tx = Transaction.new(
              txBody,
              TransactionWitnessSet.from_bytes(transactionWitnessSet.to_bytes()),
            )

            // Sign the TX
            if (this.verbose) {
              console.log('INFO: Requesting transaction signature')
            }
            let txVkeyWitnesses = await this.API.signTx(Buffer.from(tx.to_bytes(), "utf8").toString("hex"), true)
            txVkeyWitnesses = TransactionWitnessSet.from_bytes(Buffer.from(txVkeyWitnesses, "hex"));
            transactionWitnessSet.set_vkeys(txVkeyWitnesses.vkeys());
            const signedTx = Transaction.new(
              tx.body(),
              transactionWitnessSet
            );

            // Submit the TX
            const submittedTxHash = await this.API.submitTx(Buffer.from(signedTx.to_bytes(), "utf8").toString("hex"));
            if (submittedTxHash) {
              // Add customer to DB and send confirmation email
              const info = this.txInfo(submittedTxHash)
              //this.addCustomerToDB(submittedTxHash);
              await addCustomer(info);
              if (validateEmail(this.formConfirmTx.email)) {
                emailConfirmTX(info);
              }
              this.formConfirmTxIsSubmitted = true;
            }
            if (this.verbose) {
              console.log('TX Hash: ' + submittedTxHash)
            }
          } catch (err) {
            console.log('ERROR: Not able to sign and submit TX')
            this.formConfirmTxHasErrored = true;
          }
          this.formConfirmTxIsLoading = false;
        } else {
          console.log('ERROR: Wallet network mismatch')
          this.formConfirmTxHasErrored = true;
        }
      } else {
        console.log('ERROR: Wallet not connected')
        this.formConfirmTxHasErrored = true;
      }
    },
  },
};
</script>
<style>
 .btn {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
