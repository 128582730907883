import { doc, getDoc, setDoc, addDoc, collection } from "firebase/firestore";
import {firestore} from "../firebase";

export const walletExists = async (address) => {
  if (address) {
    const reference = doc(firestore, "wallets", address);
    const snapshot = await getDoc(reference);
    return snapshot.exists();
  }
  return false;
};

export const linkUserToDB = async (userInfo) => {

    const address = userInfo.addr
    const balance = userInfo.userBalance
    const logIn = userInfo.loginTime
    const walletName = userInfo.walletName
  
    try {
      if (address) {
        const reference = doc(firestore, "wallets", address);
        const snapshot = await getDoc(reference);
        if (snapshot.exists()) {

            const existingWallet = snapshot.data();
            const updatedWallet = await addWalletLoginData(existingWallet, logIn, balance, walletName);

          return updatedWallet;
        } else {
          const wallet = {
            address,
            balance,
            walletVendorHistory: [walletName],
            balanceHistory: [balance],
            loginHistory: [logIn],
          };
          await saveWallet(wallet, address);

          return wallet;
        }
      }
    } catch (error) {
      console.error(`Unexpected error in getWallet. [Message: ${error.message}]`);
      throw new Error("COULD_NOT_RETRIEVE_WALLET_FROM_DB");
    }
  };


 export const addWalletLoginData = async (wallet, logIn, balance, walletName) => {
    if (wallet) {
        const updatedWallet = {
        ...wallet,
        loginHistory: [...wallet.loginHistory, logIn],
        balanceHistory: [...wallet.balanceHistory, balance],
        walletVendorHistory: [...wallet.walletVendorHistory, walletName]
      };
      await saveWallet(updatedWallet);
      return updatedWallet;
    }
    return wallet;
  };

/**
 * @throws COULD_NOT_SAVE_WALLET_TO_DB
 */
export const saveWallet = async (wallet, merge = true) => {
  try {
    if (wallet) {
      const reference = doc(firestore, "wallets", wallet.address);
      await setDoc(reference, wallet, { merge });
    }
  } catch (error) {
    console.error(
      `Unexpected error in saveWallet. [Message: ${error.message}]`
    );
    throw new Error("COULD_NOT_SAVE_WALLET_TO_DB");
  }
};
