<template>
  <div class="mt-3 mb-4" style="justify-content: center; display: flex;">
    <div class="looping-rhombuses-spinner">
      <div class="rhombus"></div>
      <div class="rhombus"></div>
      <div class="rhombus"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "loader-looping-rhombuses",
}
</script>
