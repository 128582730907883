import { createRouter, createWebHashHistory } from "vue-router";

// Components pages
import DashboardLayout from "@/views/Layout/DashboardLayout";
import Checkout from "../pages/Checkout.vue";


const routes = [
  {
    path: "/",
    redirect: "/checkout",
    component: DashboardLayout,
    name: "Events",
    children: [
      {
        path: "/checkout",
        name: "Events",
        components: { default: Checkout },
      }]
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkActiveClass: "active",
  routes,
});

export default router;
