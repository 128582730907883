<template>
  <div class="header" :class="gradient">
    <div class="container-fluid">
      <div class="header-body">
        <slot></slot>
      </div>
    </div>

    <form>
                                <base-input v-if='colorMode' name="select" @change="changeBG($event)"> 
                                <select class="form-control" id="exampleFormControlSelect1">
                                    <option value="bg-header-gradient-1">bg-header-gradient-1</option>
                                    <option value="bg-header-gradient-2">bg-header-gradient-2</option>
                                    <option value="bg-header-gradient-3">bg-header-gradient-3</option>
                                    <option value="bg-header-gradient-4">bg-header-gradient-4</option>
                                    <option value="bg-header-gradient-5">bg-header-gradient-5</option>
                                    <option value="bg-header-gradient-6">bg-header-gradient-6</option>
                                    <option value="bg-header-gradient-7">bg-header-gradient-7</option>
                                </select>
                                </base-input>
                            </form>
  </div>

</template>
<script>
export default {
  data() {
    return {
      colorMode: 0,
      gradient: "bg-header-gradient-7",
    }
  },
  name: "base-header",
  props: {
    type: {
      type: String,
      description: "Header background type",
    },
  },
  methods: {
    changeBG() {
      this.gradient = event.target.value
      console.log(this)
    }
  }

};
</script>
<style></style>
