import Badge from "../components/Badge";
import BaseButton from "../components/BaseButton";
import BaseHeader from "../components/BaseHeader";
import BaseInput from "../components/Inputs/BaseInput";
import BaseNav from "../components/Navbar/BaseNav";
import Card from "../components/Cards/Card";
import { ElTooltip, ElPopover, ElLoading } from "element-plus";

const GlobalComponents = {
  install(app) {
    app.component("badge", Badge);
    app.component("base-button", BaseButton);
    app.component("base-input", BaseInput);
    app.component("base-nav", BaseNav);
    app.component("base-header", BaseHeader);
    app.component("card", Card);
    app.use(ElTooltip);
    app.use(ElPopover);
    app.use(ElLoading);
  },
};

export default GlobalComponents;
