import axios from "axios";
export const emailConfirmTX = async (customerData) =>{
  return axios
      .post('https://h07qf4mfy1.execute-api.us-east-1.amazonaws.com/prod/txEmailer',customerData)
      .then(response => {
      if (response.status === 200) {
        //console.log('SUCCESS')
        //console.log(response)
      } else {
        //console.log('ERROR')
        //console.log(response)
      }
    })
}
export const emailConfirmWaitList = async (email) =>{
  return axios
    .post('',email)
    .then(response => {
      if (response.status === 200) {
        //console.log('SUCCESS')
        //console.log(response)
      } else {
        //console.log('ERROR')
        //console.log(response)
      }
    })
}
